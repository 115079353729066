import { combineTypePolicies, InMemoryCache } from '@nerdwallet/apollo';

import { NotificationsTypePolicies } from '@nerdwallet/membership-features/notification-center';

import type { TypedTypePolicies } from '../../../../generated-gql/generated-type-policies';

const createCache = ({ addTypename = true } = {}) => {
  return new InMemoryCache({
    addTypename,
    typePolicies: combineTypePolicies<TypedTypePolicies>([
      NotificationsTypePolicies,
    ]).typePolicies,
  });
};

export default createCache;
