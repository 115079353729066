import { Component } from 'react';
import { getLogger } from '@nerdwallet/logging';
import { logSentryError } from './sentry';

const rootLogger = getLogger('ErrorBoundary');

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    const logger = getLogger('componentDidCatch', rootLogger);
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('?debug=')
    ) {
      logger.error(`Encountered error: ${error}\nExtra: ${errorInfo}`);
    }

    logSentryError(error);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
