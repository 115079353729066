import { ApolloProvider } from '@apollo/client';
import getApolloServer from '@nerdwallet/apollo/server';
import getNerdwalletApolloClient from '@nerdwallet/apollo/client';

import apolloOptions from '../apollo/apollo-options';

export const getApolloClient = () => {
  return typeof window === 'undefined'
    ? getApolloServer(apolloOptions)
    : getNerdwalletApolloClient(apolloOptions);
};

type Props = {
  children?: any;
};

const ApolloWrapper: React.FC<Props> = ({ children }) => {
  const apolloClient = getApolloClient();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
