import createCache from './createCache';
import { resolvers } from './local-schemas';

/**
 * Empty object literals are necessary for using the Apollo client cache in the future.
 * Any local state management via apollo client via the @client directive should add
 * defaults and, if necessary, a resolver for the data types being read/written
 */
export default {
  connectToDevTools:
    process.env.CURRENT_ENV === 'development' ||
    process.env.CURRENT_ENV === 'staging',
  createCache,
  defaults: {},
  resolvers,
};
