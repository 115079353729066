// Initialize/define local schema resolvers
//
// The pattern in this file is copied from mobile-creditcards.
// https://github.com/NerdWallet/mobile-creditcards/blob/ab3aafe00abb96a30afa8017c7e48e3ae1315059/App/Apollo/local-schemas.ts
import _ from 'lodash';

import notificationCenter from '@nerdwallet/membership-features/notification-center.js';

const schemas = [notificationCenter.notificationCenterSchema];

export const typeDefs = _.compact(schemas.map((schema) => schema.typeDefs));

export const resolvers = _.merge(
  {},
  ...schemas.map((schema) => schema?.resolvers)
);
